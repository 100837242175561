<template>
  <b-row>
    <b-col cols="4">
      <b-tabs v-model="tabIndex" fill content-class="mt-3" class="tabs-base" @input="changeTabHandler">
        <b-tab title="New" />
        <b-tab title="In Service" />
        <b-tab title="History" />
      </b-tabs>
      <div class="areawrap mt-6">
        <div class="list custom-scroll">
          <b-row
            v-for="item in requirementList"
            :key="item.id"
            :class="`list-item ${selected && selected.id === item.id ? 'selected':''}`"
            @click="onRequirementClick(item.id)"
          >
            <b-col cols="12" class="mb-2 mt-4">
              {{ item.name }}
            </b-col>
            <b-col cols="12" class="mb-4">
              <b-row class="justify-content-between">
                <b-col cols="auto">
                  {{ $dayjs(item.published_at).format('M月DD日  hh:mma') }}
                </b-col>
                <b-col cols="auto ml-auto">
                  <service-type-tags :types="[item.service_type]" />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-col>

    <b-col cols="8" class="pl-6">
      <div v-if="selected" class="areawrap">
        <div class="d-flex justify-content-between">
          <h2>{{ selected.corporate_name }}</h2>
        </div>
        <b-row class="my-4">
          <b-col cols="4">
            <b-form-group label="Location" label-class="text-primary mb-1">
              {{ selected.corporate_city }}, {{ country }}
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Technician" label-class="text-primary mb-1">
              {{ technician }}
            </b-form-group>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-between align-items-center">
          <h2>SN {{ selected.serial_number }}</h2>
          <service-type-tags :types="[selected.service_type]" />
        </div>
        <hr class="mt-2">
        <b-row class="my-4">
          <b-col cols="4">
            <b-form-group label="Model" label-class="text-primary mb-1">
              {{ getModelText(selected.model) }}
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Warranty" label-class="text-primary mb-1">
              {{ selected.warranty }}
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Date Installed" label-class="text-primary mb-1">
              {{ selected.service_applied_time }}
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Status" label-class="text-primary mb-1">
              {{ selected.autoclave_message }}
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Content" label-class="text-primary mb-1">
              {{ selected.service_content }}
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col cols="12">
            <div v-for="item in selected.conversations" :key="item.id">
              <div class="d-flex justify-content-between align-items-center mb-3">
                <span class="font-weight-bold">{{ item.publisher.name }}</span>
                <span class="caption">{{ item.published_at }}</span>
              </div>
              <div class="mb-3">
                {{ item.content }}
              </div>
              <hr class="mb-4">
            </div>
          </b-col>
          <b-col v-if="tabIndex !== 2" cols="12">
            <b-form-textarea v-model="requirementContent" />
            <b-button variant="primary" class="btn-width mt-2" @click="createRequirement">
              確認送出
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import serviceTypeTags from '@/components/common/serviceTypeTags'
import countryList from '@/common/countryList'
import modelList from '@/common/modelList'
import { getRequirementList, getRequirement, createRequirement } from '@/api/requirement'
export default {
  name: 'Requirement',
  components: {
    serviceTypeTags
  },
  data () {
    return {
      tabIndex: 0,
      requirementList: [],
      requirementContent: '',

      selected: null
    }
  },
  computed: {
    modelList () {
      return modelList
    },
    countryList () {
      return countryList
    },
    country () {
      return countryList.find(c => c.value === this.selected.corporate_country)?.text
    },
    technician () {
      if (this.selected) return this.selected.technicians.join(', ')
      else return ''
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.getRequirementList()
    },
    changeTabHandler () {
      this.getRequirementList()
    },
    getRequirementList (id = null) {
      getRequirementList(this.tabIndex).then(res => {
        this.requirementList = res.data.data
        if (this.requirementList.length > 0) {
          this.getRequirementDetail(id === null ? this.requirementList[0].id : id)
        } else {
          this.selected = null
        }
      })
    },
    onRequirementClick (id) {
      this.getRequirementDetail(id)
    },
    getRequirementDetail (id) {
      getRequirement(id).then(res => {
        this.selected = res.data.data
      })
    },
    createRequirement () {
      if (this.requirementContent === '') return 0
      const data = {
        service_id: this.selected.service_id,
        content: this.requirementContent
      }
      createRequirement(data).then(res => {
        this.tabIndex = 1
        this.getRequirementList(this.selected.id)
        this.requirementContent = ''
      })
    },
    getModelText (model) {
      return modelList.map(p => p.options).flat().find(a => a.value === model)?.text
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~@/assets/style/variables';

.list{
  height: 530px;
  max-height: 530px;
  overflow-y: auto;
}
.list-item{
  margin: 0;
  border-bottom: $gray-300 solid;
  cursor: pointer;
}

.selected{
  box-shadow: inset -10px -10px 10px 0 rgba(255, 255, 255, 0.7), inset 10px 10px 10px 0 rgba(174, 174, 192, 0.2);
  background-color: #f0f0f5;
  border-radius: 12px;
}
</style>
