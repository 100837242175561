import apiService from '@/common/api.service'

export function getRequirementList (status) {
  return apiService({
    url: '/api/requirement/status',
    method: 'get',
    params: { status }
  })
}

export function getRequirement (id) {
  return apiService({
    url: '/api/requirement',
    method: 'get',
    params: { id }
  })
}

export function createRequirement (data) {
  return apiService({
    url: '/api/requirement',
    method: 'post',
    data
  })
}
